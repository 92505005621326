import { defineColumn, transferRowDataInternal } from "presentation/view/components/TableWrapper/BasicTableConstants";
import { BillingTypeMappingConstant } from "./BillingTypeMappingConstant";

const BILLING_TYPE_MAPPING_CONSTANT = BillingTypeMappingConstant.Table;
let dateFieldList:string[] = [];
let dateTimeFieldList:string[] = [];
export const INITIAL_BILLING_TYPE_MAPPING_COL_DEF: any[] = [

    {
        headerName: BILLING_TYPE_MAPPING_CONSTANT.PRIORITY,
        field: "priority",
        enableRowGroup: false,
        enablePivot: true,
        enableValue: false,
        rowGroup: false,
        filter: false,
        width: 150,
        // pinned: "left",
        checkboxSelection: true,
        headerCheckboxSelection: true,
        },
        {
        headerName: BILLING_TYPE_MAPPING_CONSTANT.CHARGE_TYPE,
        field: "chargeType",
        enableRowGroup: false,
        enablePivot: true,
        enableValue: false,
        rowGroup: false,
        filter: false,
        width: 150,
        },
        {
        headerName: BILLING_TYPE_MAPPING_CONSTANT.SUB_CHARGE_TYPE,
        field: "subChargeType",
        enableRowGroup: false,
        enablePivot: true,
        enableValue: false,
        rowGroup: false,
        filter: false,
        width: 180,
        },
        {
        headerName: BILLING_TYPE_MAPPING_CONSTANT.CHARGEON_COMPANY,
        field: "chargeOnCompany",
        enableRowGroup: false,
        enablePivot: true,
        enableValue: false,
        rowGroup: false,
        filter: false,
        width: 200,
        },
        {
        headerName: BILLING_TYPE_MAPPING_CONSTANT.BILLTO_COMPANY,
        field: "billToCompany",
        enableRowGroup: false,
        enablePivot: true,
        enableValue: false,
        rowGroup: false,
        filter: false,
        width: 180,
        },
        {
        headerName: BILLING_TYPE_MAPPING_CONSTANT.OPS_LINE,
        field: "opsLine",
        enableRowGroup: false,
        enablePivot: true,
        enableValue: false,
        rowGroup: false,
        filter: false,
        width: 120,
        },
        {
        headerName: BILLING_TYPE_MAPPING_CONSTANT.MARSHALLING_TYPE,
        field: "marshallingType",
        enableRowGroup: false,
        enablePivot: true,
        enableValue: false,
        rowGroup: false,
        filter: false,
        width: 180,
        },
        {
        headerName: BILLING_TYPE_MAPPING_CONSTANT.SIZE,
        field: "cntrSize",
        enableRowGroup: false,
        enablePivot: true,
        enableValue: false,
        rowGroup: false,
        filter: false,
        width: 100,
        },
        {
        headerName: BILLING_TYPE_MAPPING_CONSTANT.TYPE,
        field: "cntrType",
        enableRowGroup: false,
        enablePivot: true,
        enableValue: false,
        rowGroup: false,
        filter: false,
        width: 100,
        },
        {
        headerName: BILLING_TYPE_MAPPING_CONSTANT.STATUS,
        field: "cntrStatus",
        enableRowGroup: false,
        enablePivot: true,
        enableValue: false,
        rowGroup: false,
        filter: false,
        width: 100,
        },
        {
        headerName: BILLING_TYPE_MAPPING_CONSTANT.SHIPMENT_TYPE,
        field: "shipmentType",
        enableRowGroup: false,
        enablePivot: true,
        enableValue: false,
        rowGroup: false,
        filter: false,
        width: 170,
        },
        {
        headerName: BILLING_TYPE_MAPPING_CONSTANT.SERVICE_CODE,
        field: "serviceCode",
        enableRowGroup: false,
        enablePivot: true,
        enableValue: false,
        rowGroup: false,
        filter: false,
        width: 160,
        },
        {
        headerName: BILLING_TYPE_MAPPING_CONSTANT.MODALITY_OF_ARRIVAL,
        field: "modalityOfArrival",
        enableRowGroup: false,
        enablePivot: true,
        enableValue: false,
        rowGroup: false,
        filter: false,
        width: 190,
        },
        {
        headerName: BILLING_TYPE_MAPPING_CONSTANT.MODALITY_OF_DEPARTURE,
        field: "modalityOfDeparture",
        enableRowGroup: false,
        enablePivot: true,
        enableValue: false,
        rowGroup: false,
        filter: false,
        width: 220,
        },
        {
        headerName: BILLING_TYPE_MAPPING_CONSTANT.MARSHALLING_FROM,
        field: "marshallingFrom",
        enableRowGroup: false,
        enablePivot: true,
        enableValue: false,
        rowGroup: false,
        filter: false,
        width: 180,
        },
        {
        headerName: BILLING_TYPE_MAPPING_CONSTANT.MARSHALLING_TO,
        field: "marshallingTo",
        enableRowGroup: false,
        enablePivot: true,
        enableValue: false,
        rowGroup: false,
        filter: false,
        width: 160,
        },
        {
        headerName: BILLING_TYPE_MAPPING_CONSTANT.FROM_TML,
        field: "fromTml",
        enableRowGroup: false,
        enablePivot: true,
        enableValue: false,
        rowGroup: false,
        filter: false,
        width: 120,
        },
        {
        headerName: BILLING_TYPE_MAPPING_CONSTANT.TO_TML,
        field: "toTml",
        enableRowGroup: false,
        enablePivot: true,
        enableValue: false,
        rowGroup: false,
        filter: false,
        width: 120,
        },
        {
        headerName: BILLING_TYPE_MAPPING_CONSTANT.END_VISIT_IND,
        field: "endVisitInd",
        enableRowGroup: false,
        enablePivot: true,
        enableValue: false,
        rowGroup: false,
        filter: false,
        width: 150,
        },
        {
        headerName: BILLING_TYPE_MAPPING_CONSTANT.CHARGE_IND,
        field: "chargeInd",
        enableRowGroup: false,
        enablePivot: true,
        enableValue: false,
        rowGroup: false,
        filter: false,
        width: 140,
        },
        {
        headerName: BILLING_TYPE_MAPPING_CONSTANT.SPECIAL_HANDLING_IND,
        field: "specialHandlingInd",
        enableRowGroup: false,
        enablePivot: true,
        enableValue: false,
        rowGroup: false,
        filter: false,
        width: 220,
        },
        {
        headerName: BILLING_TYPE_MAPPING_CONSTANT.SPECIAL_HANDLING_IND_D1,
        field: "legSpHandlingInd",
        enableRowGroup: false,
        enablePivot: true,
        enableValue: false,
        rowGroup: false,
        filter: false,
        width: 240,
        },
        {
        headerName: BILLING_TYPE_MAPPING_CONSTANT.BUNDLE_IND,
        field: "bundleInd",
        enableRowGroup: false,
        enablePivot: true,
        enableValue: false,
        rowGroup: false,
        filter: false,
        width: 150,
        },
        {
        headerName: BILLING_TYPE_MAPPING_CONSTANT.TRANSPORTATION_STATUS,
        field: "cntrTspStatus",
        enableRowGroup: false,
        enablePivot: true,
        enableValue: false,
        rowGroup: false,
        filter: false,
        width: 220,
        },
        {
        headerName: BILLING_TYPE_MAPPING_CONSTANT.OPERATION_TYPE,
        field: "operationType",
        enableRowGroup: false,
        enablePivot: true,
        enableValue: false,
        rowGroup: false,
        filter: false,
        width: 180,
        },
        {
        headerName: BILLING_TYPE_MAPPING_CONSTANT.BILLING_TYPE,
        field: "billingType",
        enableRowGroup: false,
        enablePivot: true,
        enableValue: false,
        rowGroup: false,
        filter: false,
        width: 150,
        },
        {
        headerName: BILLING_TYPE_MAPPING_CONSTANT.ACTIVE_IND,
        field: "activeInd",
        enableRowGroup: false,
        enablePivot: true,
        enableValue: false,
        rowGroup: false,
        filter: false,
        width: 130,
        },
        
].map((col, index) => {
    const cellRenderers:{[key:string]:((params:{[key:string]:string}, fieldName:string) => {})} = {};

    return defineColumn(col, index, dateFieldList, dateTimeFieldList, [], cellRenderers);
});

export const transferRowData = (data:any[]) => {
    const externalFnctions:{[key:string]:((fieldName:string, row:any) => {})} = {};

    return transferRowDataInternal(data, dateFieldList, dateTimeFieldList, [], externalFnctions);
}