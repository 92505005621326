export const WorkspaceConstant = {
    MiniToolBar: {
        SET_DEFAULT_BU: "Set as default",
        WORKING_COMPANY: "Working Company",
    },
    Common: {
        CRITERIA_DIRECTORY: "CRITERIA DIRECTORY",
        BUTTON_IMPORT:"Import",
        BUTTON_RESET:"Reset",
        BUTTON_SEARCH:"Search",
        BUTTON_CLOSE:"Close",
        BUTTON_CANCEL:"Cancel",
        BUTTON_NEXT:"Next",
        BUTTON_PREV:"Prev",
        BUTTON_APPLY:"Apply",
        BUTTON_CONFIRM:"Confirm",
        BUTTON_CONFIRMALL:"Confirm All",
        BUTTON_APPLYALL:"Apply All",
        BUTTON_UNCONFIRM:"Un-Confirm",
        BUTTON_UNCONFIRMALL:"Un-Confirm All",
        BUTTON_UPDATE:"Update",
        BUTTON_UPDATEALL:"Update All",
        BUTTON_SAVE:"Save",
        BUTTON_COPY:"Copy",
        BUTTON_MORE:"More",
        BUTTON_DELETE:"Delete",
        BUTTON_ADJUSTMENT: "Adjustment",
        BUTTON_REPRINT:"Reprint",
        BUTTON_ACCTML:"Acc Tml",
        BUTTON_RESUMEACCTML:"Resume Acc Tml",
        BUTTON_GENNONOPS:"Gen. Non-Ops",
        BUTTON_UPLOAD:"Upload",
        BUTTON_VIEW:"View",
        BUTTON_SHOWALL:"Show All",
        BUTTON_HIDEOTHERS:"Hide Others",
        BUTTON_GENDRAFTINVOICE:"Gen Draft Invoice",
        BUTTON_COPYARRIVALVESSEL:"Copy Arrival Vessel",
        BUTTON_COPYDEPATUREVESSEL:"Copy Depature Vessel",
        BUTTON_UPLOADSUPPORTING:"Upload Supporting",
        BUTTON_VIEWSUPPORTING:"View Supporting",
        BUTTON_UPLOADCNTR:"Upload Container",
        BUTTON_ADD:"Add",

        MENU_MOVE_AFTER: "Move After",
        MENU_MOVE_CONFIRM: "Move Confirm"
    }
}