import { BillingTypeMappingConstant } from "presentation/constant/BillingTypeMapping/BillingTypeMappingConstant";
import { WorkspaceConstant } from "presentation/constant/WorkSpaceConstant";
import { useBillingTypeMappingVM } from "presentation/hook/BillingTypeMapping/useBillingTypeMappingVM";
import { useMessageBarVM } from "presentation/hook/useMessageBar";
import { useBillingTypeMappingTracked } from "presentation/store/BillingTypeMapping/BillingTypeMappingProvider";
import FileUpload from "presentation/view/components/FileUpload";
import { HeaderTitle } from "presentation/view/components/HeaderWithBackButton/HeaderTitle";
import { memo, useCallback, useState } from "react";
import { IconButton, Loader } from "veronica-ui-component/dist/component/core";
import { Sidebarheader, StyledAction } from "veronica-ui-component/dist/component/core/styled/uiFramework.styled";

const BillingTypeMappingTitleBar:React.FC = () => {
    const BILLING_TYPE_MAPPING_CONSTANT= BillingTypeMappingConstant.Title;
    const billingTypeMappingVM = useBillingTypeMappingVM();
    const [billingTypeMappingState] = useBillingTypeMappingTracked();
    const [isLoading, setIsLoading] = useState(false);
    const {updatedRows} = billingTypeMappingState;
    const messageBarVM = useMessageBarVM();  
    
    const handleAdd = useCallback(() => {
        billingTypeMappingVM.onAddClick();
    }, [billingTypeMappingVM]);

    // const handleApply = useCallback(async () => {
    //     if(_.isEmpty(updatedRows)) return;
    //     setIsLoading(true);
    //     const res = await billingTypeMappingVM.onApply(updatedRows);
    //     setIsLoading(false);
    //     if(!!!res || !res.success){
    //         messageBarVM.showWarining(res.data);
    //     }else{
    //         messageBarVM.showSuccess('Apply Successful.');
    //         await billingTypeMappingVM.searchAllBillingTypeMappingList();
    //     }
    //     setIsLoading(false);
    // }, [billingTypeMappingVM, messageBarVM, updatedRows]);

    
    // const handleRefresh = useCallback(async () => {
    //     setIsLoading(true);
    //     billingTypeMappingVM.searchAllBillingTypeMappingList().then((data) => {            
    //         setIsLoading(false)
    //     }).catch(error => {            
    //         setIsLoading(false)
    //     });
    // },[billingTypeMappingVM]);

    const handleUpload = useCallback(async (e:any) => {

        const files = e.target?.files;
        const file = files[0];

        if(!file){
            return;
        }
        if (file.type !== 'text/csv') {
            messageBarVM.showWarining('Please upload a .csv file');
            return
        }
        
        setIsLoading(true);
        const res = await billingTypeMappingVM.onUpload(file);
        
        file.current.value = null;
        e.target = null;

        if(res.success){
            messageBarVM.showSuccess("Success!");
        }else{
            messageBarVM.showError(res.data);
        }

        setIsLoading(false);


        billingTypeMappingVM.searchAllBillingTypeMappingList();

    }, [messageBarVM, billingTypeMappingVM]);

    const isApplyDisable = () => {
        if(updatedRows.length !== 0) return false
        return true;
    }

    return <Sidebarheader style={{width: '100%', display:"flex", alignItems:"center"}}>
        <HeaderTitle>{BILLING_TYPE_MAPPING_CONSTANT.TITLE}</HeaderTitle>
        {(isLoading) && <Loader Indicator="Spinner" size="Medium" /> }
        <StyledAction className="tm-animated-wrapper">
            {/* <IconButton fileName='Icon-reload' disabled={false} size='medium' toolTipText={'Refresh'} onClick={handleRefresh}/> */}
            {/* <div className="add-seperator"/> */}
            <IconButton fileName='Icon-add' disabled={!isApplyDisable()} size='medium' toolTipText={'Add'} toolTipArrow={false} onClick={handleAdd} />
            <div className="add-seperator"/>
            <FileUpload handleUpload={handleUpload} label={WorkspaceConstant.Common.BUTTON_UPLOAD} accept=".csv" size={"Small"} theme={"Primary"} disabled={!isApplyDisable()}/>
            {/* <HPHButton label={WorkspaceConstant.Common.BUTTON_APPLY} size={"Small"} theme={"Primary"}  disabled={isApplyDisable()} onClick={handleApply} /> */}
        </StyledAction>
    </Sidebarheader>
}

export default memo(BillingTypeMappingTitleBar);